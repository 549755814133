import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import {
  UPDATE_SEARCH_FILTERS,
  CLEAR_SEARCH_FILTERS,
  SET_OPEN_SEARCH_FILTER,
  SET_MORE_FILTERS_OPEN,
  SET_PAGE_SEARCH_RESULTS,
} from '../../actions';

const defaultSearchFiltersState = {
  openSearchFilter: null,
  moreFiltersOpen: false,
  page: 1,
  parameters: {
    s: 'score DESC',
  },
};

const VALID_FILTER_VALUES = [
  'address', 'zoom', 'space_type_ids', 'listing_feature_ids', 'listing_feature_ids_contains_array',
  'project_type_ids', 'parent_project_type_id', 'position_types',
  'latitude_gteq', 'latitude_lteq', 'longitude_gteq', 'longitude_lteq',
  'latitude', 'longitude', 'daily_price_gteq', 'daily_price_lteq',
  'weekly_price_gteq', 'weekly_price_lteq', 'parent_project_type_ids_contains_element',
  'monthly_price_gteq', 'monthly_price_lteq',
  'lat_g', 'lat_l', 'lng_g', 'lng_l',
  'size_gteq', 'size_lteq', 's', 'country', 'city',
  'duration', 'start_date', 'end_date', 'fit_bounds', 'foot_traffic',
  'is_for_lease', 'is_for_revenue_share', 'is_for_sale', 'is_for_short_term',
];

export default (searchFilterState = defaultSearchFiltersState, { type, payload }) => {
  switch (type) {
    case SET_PAGE_SEARCH_RESULTS: {
      return {
        ...searchFilterState,
        page: payload,
      };
    }
    case 'UPDATE_BOUNDING_BOX': {
      const { parameters } = searchFilterState;
      const validFilterParameters = pick(payload, [
        'latitude_gteq',
        'latitude_lteq',
        'longitude_gteq',
        'longitude_lteq',
        'longitude',
        'latitude',
        'zoom',
      ]);

      // Create a new set of parameters from the new parameter and the existing parameters
      const updatedFilterState = {
        parameters: {
          ...parameters,
          ...validFilterParameters,
        },
      };

      // Return a new state made of the previous state and our updated filter parameters
      return {
        ...searchFilterState,
        ...updatedFilterState,
      };
    }
    case UPDATE_SEARCH_FILTERS: {
      const { parameters } = searchFilterState;
      const validFilterParameters = pick(payload, VALID_FILTER_VALUES);

      // Create a new set of parameters from the new parameter and the existing parameters
      const updatedFilterState = {
        parameters: {
          ...parameters,
          ...validFilterParameters,
          // Coordinates
          latitude_gteq: payload.lat_g || validFilterParameters.latitude_gteq || parameters.latitude_gteq,
          latitude_lteq: payload.lat_l || validFilterParameters.latitude_lteq || parameters.latitude_lteq,
          longitude_gteq: payload.lng_g || validFilterParameters.longitude_gteq || parameters.longitude_gteq,
          longitude_lteq: payload.lng_l || validFilterParameters.longitude_lteq || parameters.longitude_lteq,
        },

        // Close any open filters
        openSearchFilter: null,
      };

      const featureIds = validFilterParameters.listing_feature_ids || validFilterParameters.listing_feature_ids_contains_array || parameters.listing_feature_ids_contains_array;

      if (featureIds) {
        updatedFilterState.parameters.listing_feature_ids_contains_array = featureIds;
        delete updatedFilterState.parameters.listing_feature_ids;
      }

      if (!updatedFilterState.parameters.duration) {
        delete updatedFilterState.parameters.duration;
      }

      // Return a new state made of the previous state and our updated filter parameters
      return {
        ...searchFilterState,
        ...updatedFilterState,
      };
    }
    case CLEAR_SEARCH_FILTERS: {
      const { parameters } = searchFilterState;

      // The sort order should be preserved from the previous filter state
      const emptyFilterState = {
        s: parameters.s,
      };

      const remainingFilters = omit(parameters, payload);

      const updatedFilterState = isEmpty(payload)
        ? emptyFilterState
        : remainingFilters;

      return {
        moreFiltersOpen: searchFilterState.moreFiltersOpen,
        parameters: {
          ...updatedFilterState,
        },
      };
    }

    case SET_OPEN_SEARCH_FILTER: {
      const updatedFilterState = {
        openSearchFilter: payload,
      };

      return {
        ...searchFilterState,
        ...updatedFilterState,
      };
    }

    case SET_MORE_FILTERS_OPEN: {
      const updatedFilterState = {
        moreFiltersOpen: payload,
      };

      return {
        ...searchFilterState,
        ...updatedFilterState,
      };
    }

    default:
      return searchFilterState;
  }
};
