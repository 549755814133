import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import pick from 'lodash/pick';
import axios from 'axios';
import { Agent as httpAgent } from 'http';

import {
  // User details
  USER_FETCH,
  userFetchSuccess,
  userFetchError,
  // Logout
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  logoutUserSuccess,
  // Client secret
  clientSecretFetchSuccess,
  // Update language
  UPDATE_USER_LANGUAGE,
  UPDATE_USER_LANGUAGE_SUCCESS,
  updateUserLanguageSuccess,
  // Update subscription
  UPDATE_SUBSCRIPTION,
  updateSubscriptionSuccess,
  // Load billing page
  OPEN_BILLING_PAGE,
  openBillingPage,

} from '../actions';

const PaymentAPIUrl = (typeof window !== 'undefined') && window.env.payment_api_base_url
  ? window.env.payment_api_base_url
  : 'https://payment.thestorefront.com/api';

const APIBaseUrl = (typeof window !== 'undefined')
  ? window.env.api_base_url
  : (require('../../../../../lib/environment')).secrets.apiBase;

const defaultAxiosConfiguration = {
  timeout: 65000,
  withCredentials: true,
  httpAgent: new httpAgent({ keepAlive: true }),
};

function* getDetails() {
  try {
    const response = yield call(
      axios.get,
      `${APIBaseUrl}/v1/users/${window.USER.data[0].id}/extended`,
      { ...defaultAxiosConfiguration },
    );

    yield put(userFetchSuccess(response.data.data[0]));
  } catch (e) {
    yield put(userFetchError({ message: e.message }));
  }
}

function* logout() {
  try {
    yield call(
      axios.delete,
      `${APIBaseUrl}/v1/sessions/logout`,
      { ...defaultAxiosConfiguration },
    );

    yield put(logoutUserSuccess());
  } catch (e) {
    // yield put(userFetchError({ message: e.message }));
  }
}

function* getClientSecret({ payload }) {
  const { user } = yield select();
  const options = pick(payload, ['currency', 'billingAddress', 'schedulePlan']);

  try {
    const response = yield call(
      axios.post,
      `${PaymentAPIUrl}/create-subscription`,
      {
        customerId: user.stripe_customer.id,
        ...options,
      },
      {
        timeout: 65000,
        httpAgent: new httpAgent({ keepAlive: true }),
      },
    );

    if (options.currency) {
      yield put(updateSubscriptionSuccess(response.data));
    } else {
      yield put(clientSecretFetchSuccess(response.data));
    }
  } catch (e) {
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (clientSecret) {
      yield put(clientSecretFetchSuccess({ clientSecret }));
    } else {
      const response = e.toJSON();

      if (/409/.test(response.message)) {
        yield put(openBillingPage());
      } else {
        window.location.href = '/account/subscriptions';
      }
    }
  }
}

function* updateLanguage({ payload }) {
  try {
    yield call(
      axios.put,
      `${APIBaseUrl}/v1/users/${window.USER.data[0].id}`,
      { preferred_language: payload },
      { ...defaultAxiosConfiguration },
    );

    yield put(updateUserLanguageSuccess());
  } catch (e) {
    // TODO: Handle error
    // yield put(userFetchError({ message: e.message }));
  }
}

function* loadBillingPage() {
  try {
    const response = yield call(
      axios.get,
      `${APIBaseUrl}/v1/sessions/billing_link`,
      { ...defaultAxiosConfiguration },
    );

    window.location.href = response.data.data.url;
  } catch (e) {
    window.location.href = '/account/subscriptions';
  }
}

export default function* userSagas() {
  yield all([
    takeLatest(USER_FETCH, getDetails),
    takeLatest(UPDATE_SUBSCRIPTION, getClientSecret),
    takeLatest(LOGOUT_USER, logout),
    takeLatest(UPDATE_USER_LANGUAGE, updateLanguage),
    takeLatest(LOGOUT_USER_SUCCESS, () => {
      window.location.reload();
    }),
    takeLatest(UPDATE_USER_LANGUAGE_SUCCESS, () => {
      window.location.reload();
    }),
    takeLatest(OPEN_BILLING_PAGE, loadBillingPage),
  ]);
}
