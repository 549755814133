import reduce from 'lodash/reduce';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import countBy from 'lodash/countBy';
import orderBy from 'lodash/orderBy';

import {
  LISTING_SINGLE_SEARCH_SUCCESS,
  LISTING_SEARCH_PERFORMED,
  LISTING_SEARCH_SUCCESS,
} from '../../actions';

const defaultState = {
  cachedListings: {},
  isLoading: true,
  listings: [],
  markers: [],
  meta: {
    page: 1,
    count: 0,
    total: 0,
  },
  locale: {
    search: {
      price_max: null,
    },
    area: {
      unit: '',
      format: '',
    },
    currency: {
      format: '',
    },
  },
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case LISTING_SINGLE_SEARCH_SUCCESS: {
      return {
        ...state,
        cachedListings: {
          ...state.cachedListings,
          [payload.data.id]: payload.data,
        },
      };
    }

    case LISTING_SEARCH_PERFORMED: {
      return {
        ...state,
        searchFilters: payload,
        isLoading: true,
      };
    }

    case LISTING_SEARCH_SUCCESS: {
      const { listings, markers, country } = payload.data;

      const locale = findMostRelevantLocale(listings, country);

      if (listings && !listings.length) {
        return {
          ...state,
          meta: payload.meta,
          isLoading: false,
          listings: [],
          markers: [],
          locale,
        };
      }

      const cached = reduce(listings, (acc, listing) => ({ ...acc, [listing.id]: listing }), {});

      return {
        isLoading: false,
        listings,
        markers,
        meta: payload.meta,
        cachedListings: {
          ...state.cachedListings,
          ...cached,
        },
        locale,
      };
    }

    default:
      return state;
  }

  function findMostRelevantLocale(listings, country) {
    const defaultLocale = find(window.locales, { name: country }) || find(window.locales, { name: 'United States' });

    if (!listings || isEmpty(listings)) {
      return defaultLocale;
    }

    const countriesOccurencesMap = countBy(listings, 'country');
    const arr = Object.keys(countriesOccurencesMap).map((countryName) => ({
      countryName, value: countriesOccurencesMap[countryName],
    }));
    const sorted = orderBy(arr, ['value'], ['desc']);
    return find(window.locales, { name: sorted[0].countryName }) || defaultLocale;
  }
};
