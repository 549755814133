import axios from 'axios';
const http = require('http');
import omit from 'lodash/omit';
import { stringify } from 'qs';
import {
  all,
  takeLatest,
  put,
  call,
  select,
  debounce,
} from 'redux-saga/effects';

import {
  SET_PAGE_SEARCH_RESULTS,
  UPDATE_SEARCH_FILTERS,
  LISTING_SEARCH_PERFORMED,
  LISTING_SINGLE_SEARCH_PERFORMED,
  CLEAR_SEARCH_FILTERS,
  listingSearchSuccess,
  listingSingleSearchSuccess,
  listingSearchError,
} from '../actions';

const SearchBaseUrl = (typeof window !== 'undefined')
  ? window.env.search_base_url
  : (require('../../../../../lib/environment')).secrets.searchBase;

const defaultParameters = {
  latitude_gteq: 40.642960781751015,
  latitude_lteq: 40.835457866028555,
  longitude_gteq: -73.96901118834626,
  longitude_lteq: -73.83755612452637,
  latitude: 40.73920932388978,
  longitude: -73.90328365643632,
  s: 'size ASC',
  country: 'United States',
  city: 'New York',
  zoom: 11,
  with_markers: true,
  markers_size: 1000,
};

// axios request configuration
// source: https://github.com/axios/axios#request-config
const defaultAxiosConfiguration = {
  // this value should be higher than the LB keepalive timeout
  timeout: 65000,

  // keepAlive pools and reuses TCP connections
  httpAgent: new http.Agent({ keepAlive: true }),
}

function* getListings() {
  const state = yield select();
  const { page, parameters } = state.searchFilters;

  const searchParameters = parseInt(parameters.duration, 10) === 0
    ? omit(parameters, 'duration')
    : parameters;

  try {
    const searchResults = yield call(
      axios.get,
      `${SearchBaseUrl}/v1/listings/search`,
      {
        ...defaultAxiosConfiguration,
        params: {
          page,
          per_page: 40,
          q: {
            ...defaultParameters,
            ...searchParameters,
          },
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'brackets' }),
      },
    );
    // We need the country to set a default locale for filters if no listings are returned
    searchResults.data.data.country = parameters.country;

    yield put(listingSearchSuccess(searchResults.data));
  } catch (e) {
    yield put(listingSearchError());
  }
}

function* getListing() {
  const state = yield select();
  const { selectedMarker } = state.map;

  if (!selectedMarker) {
    return;
  }

  try {
    const searchResults = yield call(
      axios.get,
      `${SearchBaseUrl}/v1/listings/${selectedMarker}`,
      {
        ...defaultAxiosConfiguration
      }
    );

    yield put(listingSingleSearchSuccess(searchResults.data));
  } catch (e) {
    // yield put(listingSearchError());
  }
}

export default function* searchFilterSagas() {
  yield all([
    debounce(
      250,
      [LISTING_SEARCH_PERFORMED, UPDATE_SEARCH_FILTERS, CLEAR_SEARCH_FILTERS],
      getListings,
    ),
  ]);

  yield all([
    takeLatest(LISTING_SINGLE_SEARCH_PERFORMED, getListing),
  ]);

  yield all([
    takeLatest(SET_PAGE_SEARCH_RESULTS, getListings),
  ]);
}
